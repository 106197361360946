
import axios from '@/utils/axios.js';


const getLogAcessos = async (cod_empresa, data_de, data_ate, cod_usuario) => {
  let ret = null;
  try {
    ret = (await axios.post('/relatorios/log_acessos', { cod_empresa, data_de, data_ate, cod_usuario })).data;
  } catch (err) {
    console.log('error on get log acessos', err);
  }

  return ret;
}


export default {
  getLogAcessos
}