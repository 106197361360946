<template>
  <div>
    <div class="p-4 text-center" v-if="loading">
      <b-spinner /><br />carregando...
    </div>
    <label>Filtrar Funcionário</label>
    <v-select
      :options="usuarios"
      label="nome"
      v-model="cod_usuario"
      :reduce="(ut) => ut.cod_usuario"
    />
    <hr />
    <div class="text-right mt-1 mb-2">
      Filtrar:
      <b-input
        inline
        style="max-width: 200px; display: inline"
        class="ml-2"
        v-model="filtrar"
      />
    </div>
    <b-table
      :items="lista"
      :fields="[
        { key: 'id', label: 'Codigo', sortable: true },
        { key: 'data', label: 'Data/Hora', sortable: true },
        { key: 'usuario', label: 'Usuário', sortable: true },
      ]"
      :filter="filtrar"
    >
      <template #cell(data)="row">
        {{ row.item.data | moment("DD/MM/YYYY HH:mm") }}
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from "moment";
import LogAcessosLib from "../../libs/LogAcessosLib";
import UsuariosLib from "../../libs/UsuariosLib";
export default {
  components: {},
  props: {
    empresaSelecionada: Array,
    dateRange: Object,
  },
  mounted() {
    (async () => {
      this.usuarios = (await UsuariosLib.get()).sort((a, b) =>
        a.nome > b.nome ? 1 : -1
      );
      console.log("usu", this.usuarios);
    })();
    this.carregar();
  },
  data() {
    return {
      loading: false,
      lista: [],
      cod_usuario: null,
      usuarios: [],
      filtrar:''
    };
  },
  watch: {
    empresaSelecionada: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    dateRange: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    cod_usuario: function () {
      this.carregar();
    },
  },
  computed: {},
  methods: {
    async carregar() {
      this.loading = true;
      this.lista = [];
      try {
        let res = await LogAcessosLib.getLogAcessos(
          this.empresaSelecionada && this.empresaSelecionada.length > 0
            ? this.empresaSelecionada.map((it) => it.cod_empresa)
            : null,
          moment(this.dateRange.start).format("YYYY-MM-DD 08:00:00"),
          moment(this.dateRange.end).add(1, "d").format("YYYY-MM-DD 08:00:00"),
          this.cod_usuario
        );
        if (res && res.success) {
          this.lista = res.data;
        }
        console.log("lista", this.lista);
      } catch (error) {
        console.log("ERROR GET LOGS", error);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
